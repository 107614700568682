<template>
  <div :class="[scjFlag ? 'scj-out' : '', 'out']">
    <div class="item_nobg" style="padding-left: 0.1rem;
        padding-right: 0.1rem;
        box-sizing: border-box;
      ">
      
      <div class="flex just-sp list_return" >
        <img src="../assets/img/return_index.png" class="return" alt="" @click="goindex" />
        <div class="logo">
          <img src="../assets/img/logo-icon.png" alt="" />
          <div>{{$t('main.titleCenter')}}</div>
        </div>
        <div class="return"></div>
      </div>
    </div>

    <van-swipe v-if="!previewFlag" class="my-swipe" @change="swipechange" ref="myswipe" :lazy-render="true">
      <van-swipe-item v-for="(item, index) in pictures" :key="index">
        <img class="swipe_img" v-lazy="(item + '?width=750').replace(/((^[A-Za-z]{4,5})+\:)/, protocol)"
          @click="changeAct(index)" alt="" srcset="" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
          {{ current + 1 }}/{{ pictures.length }}
        </div>
        <div class="custom_indicator">
          <div class="indicator_flex" v-for="(item, index) in pictures" :key="index">
            <div style="width: 0.0325rem"></div>
            <div :class="['indicator', current == index ? 'indicator_active' : '']" @click="changIndexx(index)"></div>
            <div style="width: 0.0325rem"></div>
          </div>
        </div>
      </template>
    </van-swipe>

    <div class="item" v-if="!previewFlag">
      <!-- <div class="flex just-sp item_top">
            <div class="Title">
                <div class="English">BASIC</div>
                <div class="Chinese">基础数据</div>
            </div>
            <div></div>
        </div> -->
      <div class="detail_item" style="padding-top: 0.1rem">
        <div>
          <div>
            <img src="../assets/img/mc.png" alt="" />
          </div>
          <div>{{$t('main.trname')}}：</div>
          <div :class="['good_name', name.length >= 13 ? 'good_big_name' : '']">
            {{ name }}
          </div>
        </div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/zz.png" alt="" />
        </div>
        <div>{{ scjFlag?$t('main.collector'): $t('main.author') }}：</div>
        <div class="user_name">{{ user.name }}</div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/jz.png" alt="" />
        </div>
        <div>{{$t('main.trvalue')}}</div>
        <div class="price">￥{{ format_with_regex(price) }}</div>
        <div style="margin-right: 0.15rem; color: #00b578" v-if="collector && collector.status == 3" @click="goGrap">
          {{$t('main.curve')}}
        </div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/detail_rz.png" alt="" />
        </div>
        <div>{{$t('main.holdertran')}}：</div>
        <div class="renzhen noOne" v-if="!collector || collector.status != 3">
          {{$t('main.nyc')}}
        </div>
        <div class="renzhen collector" v-else>
          <img :src="collector.avatar.replace(/((^[A-Za-z]{4,5})+\:)/, protocol)" alt="" />
          <div>{{ collector.name }}</div>
        </div>
        <div class="flex:1"></div>
        <div style="margin-right: 0.15rem; color: #3662ec" v-if="collector && collector.status == 3" @click="goRecord">
          {{$t('main.history')}}
        </div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/bm.png" alt="" />
        </div>
        <div>{{$t('main.gwtc')}}</div>
        <div>{{ serial_number }}</div>
      </div>

      <div class="success">
        <div>{{$t('main.gwtrsucccer')}}</div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/qk.png" alt="" />
        </div>
        <div>{{$t('main.bchash')}}：</div>
        <div class="overHidd">{{ uuid }}</div>
        <div style="width: 0.2rem"></div>
      </div>
      <div class="info_item" style="padding-bottom: 0.1rem">
        <div>
          <img src="../assets/img/yd.png" alt="" />
        </div>
        <div>{{$t('main.readTimes')}}</div>
        <div style="color: #171717">{{ browse_times }}次</div>
      </div>
    </div>

    <div class="item" v-if="!previewFlag">
      <div class="flex just-sp item_top">
        <div class="cw_title">{{$t('main.trdata')}}</div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/dj.png" alt="" />
        </div>
        <div>{{$t('main.trlevel')}}</div>
        <div :style="level_name == '稀有珍宝' ? 'color:#8574fa' : ''">
          {{ level_name }}
        </div>
      </div>
      <div class="detail_item">
        <div>
          <div>
            <img src="../assets/img/sj.png" alt="" />
          </div>
          <div>{{$t('main.trdata')}}：</div>
          <div>
            <div v-for="(item, index) in info" :key="index" style="color: #171717">
              {{ info[index] }}
            </div>
          </div>
        </div>
      </div>
      <div class="info_item">
        <div>
          <img src="../assets/img/ti.png" alt="" />
        </div>
        <div>{{ scjFlag? $t('main.colltime'): $t('main.timeofcrea')  }}：</div>
        <div>{{ manufacturing_date }}</div>
      </div>
      <div class="detail_item" v-show="honor.length">
        <div>
          <div>
            <img src="../assets/img/hy.png" alt="" />
          </div>
          <div>{{$t('main.trhonor')}}：</div>
        </div>
        <div v-show="!zb_HYmore">
          <div v-for="(item, index) in honor.slice(0, 5)" :key="index">
            {{ item }}
          </div>
        </div>
        <div v-show="zb_HYmore">
          <div v-for="(item, index) in honor" :key="index">{{ item }}</div>
        </div>
        <div class="showMore" v-if="honor.length > 5" @click="zb_HYmore = !zb_HYmore">
          {{ zb_HYmore? $t('main.putaway'): $t('main.seemore') }}
        </div>
      </div>
      <div class="detail_item moreText" style="padding-bottom: 0.1rem">
        <div>
          <div>
            <img src="../assets/img/ms.png" alt="" />
          </div>

          <div>{{$t('main.trdes')}}：</div>
        </div>
        <div>
          <div :class="[zb_JSmore ? '' : 'noMore']" v-html="description"></div>
        </div>
        <div class="showMore" v-if="is_description" @click="zb_JSmore = !zb_JSmore">
          {{ zb_JSmore? $t('main.putaway'): $t('main.seemore') }}
        </div>
      </div>
    </div>
    <div class="item" v-if="previewFlag">
      <div class="flex just-sp item_top">
        <div class="cw_title">{{ scjFlag?$t('main.collectIn'): $t('main.celebrityIn') }}</div>
      </div>
      <div class="master">
        <div>
          <div class="master_title">{{ user_info.title }}</div>
          <div class="master_text">
            <img src="../assets/img/ds.png" alt="" />
            <div>{{ scjFlag?$t('main.collector'): $t('main.author') }}：</div>
            <div>{{ user_info.name }}</div>
          </div>
        </div>
        <div>
          <img v-lazy="user_info.pic + '?width=216'" alt="" />
        </div>
      </div>
      <div class="bigText moreText">
        <div><img src="../assets/img/jj.png" alt="" />{{$t('main.personI')}}：</div>
        <div :class="[JSmore ? '' : 'noMore']" v-html="user_info.content"></div>
        <div class="showMore" v-if="is_user_content" @click="JSmore = !JSmore">
          {{ JSmore? $t('main.putaway'): $t('main.seemore') }}
        </div>
      </div>
    </div>
    <div class="item" v-if="previewFlag">
      <div class="bigText" style="padding-top: 0.1rem">
        <div><img src="../assets/img/dr.png" alt="" />{{ scjFlag?$t('main.collectHon'): $t('master.honor') }}</div>
        <div v-show="!HYmore">
          <div v-for="(item, index) in user_info.honor.slice(0, 5)" :key="index">
            {{ item }}
          </div>
        </div>
        <div v-show="HYmore">
          <div v-for="(item, index) in user_info.honor" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="showMore" v-if="user_info.honor.length > 5" @click="HYmore = !HYmore">
          {{ HYmore? $t('main.putaway'): $t('main.seemore') }}
        </div>
      </div>
    </div>

    <div class="item" v-if="user_info.certificate_info.length && !previewFlag">
      <div class="flex just-sp item_top" @change="swipechange1">
        <div class="cw_title">{{$t('main.trrce')}}</div>
      </div>
      <van-swipe
        class="my-swipe swipe-zhengshu"
        @change="swipechange1"
        :show-indicators="false"
        ref="myswipe"
        :lazy-render="true"
      >
      <van-swipe-item class="creti-item" v-for="(item, index) in user_info.certificate_info" :key="index">
        <img
          class="creti-image"
          v-lazy="item.pic + '?width=750'"
          @click="changeAct1(index)"
          alt=""
          srcset=""
        />
      </van-swipe-item>
        <template #indicator>
        <div class="custom-indicator">
          {{ current1 + 1 }}/{{ user_info.certificate_info.length ? user_info.certificate_info.length : 0 }}
        </div>
        <div class="custom_indicator">
          <div
            class="indicator_flex"
            v-for="(item, index) in user_info.certificate_info"
            :key="index"
          >
            <div style="width: 0.0325rem"></div>
            <div
              :class="['indicator', current1 == index ? 'indicator_active1' : '']"
              @click="changIndexx1(index)"
            ></div>
            <div style="width: 0.0325rem"></div>
          </div>
        </div>
      </template>
      </van-swipe>
      <div class="dr-title">{{  user_info.certificate_info.length ?  user_info.certificate_info[current1].content : " "}}</div>
    </div>
    <div class="item" v-if="user_info.certificate_info.length && previewFlag">
      <div class="bigText" style="padding-top: 0.1rem" @change="swipechange2">
        <div><img src="../assets/img/dr.png" alt="" />{{ scjFlag?$t('main.collector'): $t('main.celebrity') }}{{$t('main.ceofhon')}}：</div>
      </div>
      <van-swipe
        class="my-swipe swipe-zhengshu"
        @change="swipechange2"
        :show-indicators="false"
        ref="myswipe1"
        :lazy-render="true"
      >
      <van-swipe-item class="creti-item" v-for="(item, index) in user_info.certificate_info" :key="index">
        <img
          class="creti-image"
          v-lazy="item.pic + '?width=750'"
          @click="changeAct1(index)"
          alt=""
          srcset=""
        />
      </van-swipe-item>
        <template #indicator>
        <div class="custom-indicator">
          {{ current1 + 1 }}/{{ user_info.certificate_info.length ? user_info.certificate_info.length : 0 }}
        </div>
        <div class="custom_indicator">
          <div
            class="indicator_flex"
            v-for="(item, index) in user_info.certificate_info"
            :key="index"
          >
            <div style="width: 0.0325rem"></div>
            <div
              :class="['indicator', current1 == index ? 'indicator_active1' : '']"
              @click="changIndexx1(index)"
            ></div>
            <div style="width: 0.0325rem"></div>
          </div>
        </div>
      </template>
      </van-swipe>
      <div class="dr-title">{{  user_info.certificate_info.length ?  user_info.certificate_info[current1].content : " " }}</div>
    </div>

    <!-- <div
      class="item"
      v-if="false"
      style="margin-bottom: 0.45rem"
      v-show="newList.length"
    >
      <div class="flex just-sp item_top">
        <div class="cw_title">{{$t('main.celebrityNew')}}</div>
      </div>
      <div class="new_item" v-for="(item, index) in newList" :key="index">
        <div @click="gourl(item.url)">{{ item.title }}</div>
        <div></div>
      </div>
    </div> -->

    <div class="text_bottom">
      <span style="color: #4b4b4b">{{$t('main.copyacon')}}<br /></span>
      {{$t('main.tppswsc')}}<br />
      {{$t('main.platTips')}}<br />
      <span style="color: #4b4b4b">{{$t('main.noaldar')}}</span>
    </div>
  </div>
</template>

<script>
import { ImagePreview  } from "vant";
export default {
  data() {
    return {
      name: "",
      pictures: [],
      info: [],
      manufacturing_date: "",
      description: "",
      honor: [],
      price: "",
      is_rare: "",
      serial_number: "",
      uuid: "",
      is_recommend: "",
      collector: "",
      artist_id: "",
      level_name: "",
      browse_times: 0,
      newList: [],
      tuijian_show: true,
      user: {
        name: "",
        title: "",
        pic: "",
        content: "",
        honor: [],
      },
      tuijian: [],
      activeIndex: 0,
      JSmore: false,
      HYmore: false,
      isStart: false,
      zb_JSmore: false,
      zb_HYmore: false,
      showBigImg: false,
      is_description: false,
      is_user_content: false,
      current: 0,
      current1: 0,
      is_scan: false,
      previewFlag: false,
      user_info: {
        honor: [],
        certificate_info:[]
      },
      scjFlag: false,
      protocol: 'http'
    };
  },

  async mounted() {
    console.log(this.user_info,'11111')
    this.protocol = location.protocol
    let test = document.createElement('div')
    test.style.width = '3.135rem'
    test.style.fontSize = '0.15rem'
    test.innerText = '测试'
    document.body.appendChild(test)
    let test_height = test.clientHeight

    document.body.removeChild(test)
    if (this.$route.query.type == 2) {
      this.scjFlag = true
    }
    if (this.$route.query.status) {

      this.user_info = JSON.parse(localStorage.getItem('ds_data'));
      console.log( this.user_info.certificate_info)
      console.log(this.user_info)
      if (this.$route.query.status == 1) {//大师信息
        

        this.user_info.honor = this.user_info.honor ? this.user_info.honor.split("\n") : []

        let conList = this.user_info.content.split('\n')
        for (let i = 0; i < conList.length; i++) {
          if (i == 0) {
            this.user_info.content = conList[i]
            continue;
          }
          this.user_info.content = this.user_info.content + '<br>' + conList[i]
        }
        let el1 = document.createElement('div')
        el1.style.width = '3.46rem'
        el1.style.fontSize = '0.15rem'
        el1.innerHTML = this.user_info.content
        document.body.appendChild(el1)
        if (el1.clientHeight / test_height > 5) {
          this.is_user_content = true
        }

        document.body.removeChild(el1)
        this.previewFlag = true;
      } else {//珍宝信息
        
        // this.honor = this.user_info.honor;
        this.honor = this.user_info.honor ? this.user_info.honor.split("\n") : []
        console.log(this.honor)

        let desList = this.user_info.description.split('\n')
        for (let i = 0; i < desList.length; i++) {
          if (i == 0) {
            this.description = desList[i]
            continue;
          }
          this.description = this.description + '<br>' + desList[i]
        }

        let el = document.createElement('div')
        el.style.width = '3.135rem'
        el.style.fontSize = '0.15rem'
        el.innerHTML = this.description
        document.body.appendChild(el)
        if (el.clientHeight / test_height > 5) {
          this.is_description = true
        }

        document.body.removeChild(el)
        this.manufacturing_date = this.user_info.manufacturing_date;
        this.info = this.user_info.info;
        this.level_name = this.user_info.level_name;
        this.name = this.user_info.name;
        this.price = this.user_info.price;
        this.pictures = this.user_info.pictures
        this.axios
          .post("/artist/get", {
            // token: this.token
          })
          .then((res) => {

            if (res.data.data != "") {
              this.user.name = res.data.data.name;
            }
          })
          .catch((err) => {

          });
          this.previewFlag = false;
      }
    } else {
      this.is_scan = true;
    }
  },
  methods: {
    changeAct() {

      ImagePreview({
        images: this.pictures,
        startPosition: this.current,
        closeable: true,
      });
    },
    changeAct1() {
      var arrImage = []
      this.user_info.certificate_info.forEach(item => {
        arrImage.push(item.pic)
      });
      ImagePreview({
        images: arrImage,
        startPosition: this.current1,
        closeable: true,
      });
    },
    disableBrowserBack() {

    },
    gourl(url) {
      window.location.href = url;
    },
    goDetail(id) {
      if (id == this.$route.params.id) {
        return;
      }
      this.$router.push(`/detail/${id}?status=1`);
    },
    prePic() {
      // wx.previewImage({
      //     current: url, // 当前显示图片的 http 链接
      //     urls: this.pictures // 需要预览的图片 http 链接列表
      // })
      this.showBigImg = true;
    },
    picStart(e) {
      this.isStart = true;
      this.startX = e.changedTouches[0].pageX; content

      this.diff = 0;
    },
    picMove(e) {
      if (this.isStart) {
        this.diff = e.changedTouches[0].pageX - this.startX;

        if (this.diff) {
          this.$refs.pic.style.transition = null;
          this.$refs.pic.style.transform = `translateX(${this.diff}px)`;
        }
      }
    },
    picEnd(e) {
      this.isStart = false;


      if (this.diff) {
        this.$refs.pic.style.transition = "0.5s";
        this.$refs.pic.style.transform = null;
        if (this.diff < 0 && this.diff < -100) {
          if (this.activeIndex < this.pictures.length - 1) {
            this.activeIndex++;
          }
        } else if (this.diff > 0 && this.diff > 100) {
          if (this.activeIndex > 0) {
            this.activeIndex--;
          }
        }
      }
    },
    swipechange(e) {
      this.current = e;
    },
    swipechange1(e) {
      this.current1 = e;
    },
    swipechange2(e) {
      this.current1 = e;
    },
    changIndexx(index) {

      this.$refs.myswipe.swipeTo(index);
    },
    changIndexx1(index) {

      this.$refs.myswipe1.swipeTo(index);
    },
    format_with_regex(number) {
      return !(number + "").includes(".") // 就是说1-3位后面一定要匹配3位
        ? (number + "").replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
          return match + ",";
        })
        : (number + "").replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
          return match + ",";
        });
    },
    goindex() {
      if(window.history.length == 1){
        this.axios.post('auth/getUrlScheme').then((res)=>{
          let url = res.data.data.data.openlink
					window.location.href = url
        })
      }else{
        this.$router.back()
      }
    },
    goGrap() {
      this.$router.push(`/grap/${this.$route.params.id}`);
    },
    goRecord() {
      this.$router.push(`/record/${this.$route.params.id}`);
    },
  },
};
</script>

<style scoped>
.out {
  box-sizing: border-box;
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.out.scj-out {
  box-sizing: border-box;
  background: url(../assets/img/scj-in-bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}

.flex {
  display: flex;
}

.just-sp {
  justify-content: space-between;
}

.list_return {
  align-items: center;
}

.Title {
  font-size: 0.18rem;
  position: relative;
  text-align: left;
}

.just-cen {
	display: flex;
	justify-content: space-between;
	margin: 0 -0.15rem;
	padding: 0 0.15rem;
	align-items: center;
	margin-bottom: 0.1rem;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #ffffff;
  box-sizing: border-box;
  width: 100vw;
}
.logo{
  margin-right: 14%;
}
.English {
  color: #1b6a21;
  position: relative;
  top: 0.15rem;
  text-align: left;
  left: 0.35rem;
  font-size: 0.15rem;
  opacity: 0.3;
}

.Chinese {
  color: #1b6a21;
  top: -0.2rem;
  left: 0;
  position: relative;
}

.logo {
  height: 0.42rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mockdiv {
  width: 3.135rem;
}

.logo img {
  display: block;
  width: 0.66rem;
  height: 0.25rem;
  object-fit: contain;
  margin-right: 0.08rem;
}

.my-swipe {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.van-swipe-item {
  display: inline-block;
}

.logo div {
  color: #9e332e;
  font-size: 0.18rem;
  font-weight: 600;
}

.info_item {
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  color: #767575;
  padding-top: 0.04rem;
  padding-bottom: 0.04rem;
}

.detail_item {

  font-size: 0.15rem;
  color: #767575;
  padding-bottom: 0.04rem;
  padding-top: 0.04rem;
}

.detail_item>div:nth-child(1)>div:nth-child(1) {
  width: 0.15rem;
  padding-left: 0.175rem;
  padding-right: 0.1rem;
}

.detail_item>div:nth-child(1) {
  display: flex;
}

.detail_item>div:nth-child(1)>div:nth-child(1)>img {
  width: 100%;

  object-fit: contain;
}

.detail_item>div:nth-child(1)>div:nth-child(2) {
  line-height: 0.2rem;
}

.detail_item>div:nth-child(1)>div:nth-child(3) {
  width: 2.4rem;
}

.custom-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.05rem;
  right: 0.1rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.1rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0.1rem;
  color: #fff;
}
.dr-title{
  text-align: center;
  margin-top: 0.05rem !important;
  color: #888;
  padding-bottom: 0.08rem;
  height: 0.25rem;


  /* overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-flex: 1; */
}
.custom_indicator {
  width: 100%;
  height: 0.235rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  background: #bfbfbf;
  box-sizing: border-box;
}

.indicator_flex {
  display: flex;
}
.detail_item>div:nth-child(2) {
  color: #171717;
  margin: 0 0.15rem;
  margin-left: 0.465rem;
}

.detail_item>div:nth-child(3) {
  color: #171717;
  margin: 0 0.15rem;
  margin-left: 0.465rem;
}

.info_item>div:nth-child(1) {
  width: 0.15rem;
  display: flex;
  align-items: center;
  margin-left: 0.175rem;
  margin-right: 0.1rem;
  box-sizing: border-box;
}

.info_item>div:nth-child(1)>img {
  width: 0.15rem;
  object-fit: contain;
}

.info_item>div:nth-child(2) {
  margin-right: 0.05rem;
}

.info_item>div:nth-child(3) {
  flex: 1;
  text-align: left;
  color: #171717;
}

.good_name {
  font-size: 0.15rem;
  line-height: 0.2rem;
  width: 2.42rem;
  color: #202020;
}

.good_big_name {
  font-size: 0.15rem;
}

.share {
  display: flex;
  align-items: center;
  color: #1296DB;
  font-weight: 500;
  margin-right: 0.13rem;
}

.share img {
  width: 0.2rem;
  height: 0.2rem;
}

.top_item {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  height: 0.5rem;
  color: #171717;
  font-size: 0.15rem;
}

.info_img {
  margin-top: 0.07rem;
  margin-bottom: 0.05rem;
  width: 100%;
  height: 3rem;
  overflow: hidden;
}

.info_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info_img_list {
  display: flex;
  align-items: center;
  padding: 0 0.15rem;
  padding-top: 0.02rem;
  padding-bottom: 0.06rem;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.info_img_list img {
  width: 0.54rem;
  height: 0.54rem;
  object-fit: contain;
  margin-right: 0.03rem;
  border-radius: 5px;
  margin: 0 0.02rem;
}

.img_on {
  box-shadow: 0 0 0 2px #2d71d7;
}

.item_top {
  height: max-content;

}

.item_top {

  padding-top: 0.15rem;
  padding-left: 0.45rem;
  margin-bottom: 0.08rem;
}

.user_name {
  color: #202020 !important;
}

.price {
  color: #fd1c1b !important;
}

.renzhen {
  flex: none !important;
  color: #57bd2a !important;
  /* margin-right: 0.38rem; */
}

.zhuozhe {
  color: #57bd2a !important;
}

.noOne {
  color: #8f8f8f !important;
}

.overHidd {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy {
  border-radius: 0.06rem;
  height: 0.22rem;
  width: 0.45rem;
  margin-right: 0.1rem;
  text-align: center;
  color: #fff;
  font-size: 0.15rem;
  line-height: 0.22rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
}

.return {
  width: 0.065rem;
  height: 0.12rem;
  display: block;
}

.item {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.1rem;
}


.success {

  padding-left: 0.85rem;
  height: 0.35rem;
  display: flex;
  align-items: center;
  font-size: 0.13rem;
  color: #fff;
  background: url(../assets/img/shoulu.png) no-repeat;
  background-size: cover;
}

.showMore {
  width: 2.4rem;
  height: 0.36rem;
  margin: 0 auto !important;
  margin-top: 0.1rem !important;
  border-radius: 0.04rem;
  background-image: url(../assets/img/view-more.png);
  background-size: 100% 100%;
  color: black !important;
  font-size: 0.14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.master {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 0.15rem;
  color: #767575;
}

.master_title {
  width: 2.2rem;
  margin-right: 0.1rem;
  color: #171717;
  font-weight: bold;
  font-size: 0.18rem;
  padding-top: 0.1rem;
  height: 0.8rem;
  white-space: pre-wrap;
}

.master_text {

  display: flex;
  align-items: center;
  margin-bottom: 0.15rem;
}

.master_text img {
  width: 0.15rem;
  object-fit: contain;
  margin-right: 0.1rem;
}

.master_text>div:nth-child(2) {
  margin-right: 0.05rem;
  font-size: 0.15rem;
}

.master_text>div:nth-child(3) {
  margin-right: 0.05rem;
  font-size: 0.18rem;
  color: #9a2a25;
}

.master>div:nth-child(2) {
  margin-right: 0.3rem;
  width: 1.08rem;
  height: 1.17rem;
}

.master>div:nth-child(2)>svg {
  display: block;
  width: 100%;
  height: 100%;
}

.master>div:nth-child(2)>img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bigText {
  width: 100%;
  box-sizing: border-box;
  padding-right: 0.15rem;
  padding-left: 0.15rem;
  font-size: 0.15rem;
  padding-bottom: 0.1rem;
  text-align: left;
}

.bigText img {
  display: block;
  width: 0.15rem;
  object-fit: contain;
  padding-right: 0.1rem;
}

.bigText>div:nth-child(1) {
  display: flex;
  font-size: 0.15rem;
  color: #767575;
}

.bigText>div:nth-child(2) {
  margin-top: 0.15rem;
  color: #171717;
}

.bigText>div:nth-child(3) {
  margin-top: 0.15rem;
  color: #171717;
}

.noMore {
  overflow: hidden;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;

  display: -webkit-box;

  -webkit-line-clamp: 5;

  -webkit-box-flex: 5;

}

.swipe_img {
  width: 100%;
  height: 2.65rem;
  object-fit: contain;
  display: block;
  /* background: #fff; */
  /* padding: 0.1rem 0; */

  /* user-drag:none; */
}

.good {
  padding: 0 0 0.15rem;
  display: flex;
  justify-content: space-between;
}

.good_text {
  font-size: 0.17rem;
  color: #9a2a25;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  width: 1.66rem;
  height: 0.48rem;
  padding: 0 0.1rem;
  line-height: 0.48rem;
  text-overflow: ellipsis;
  text-align: left;
  background: #fff;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-flex: 1;
}

.good .good-image {
  width: 100%;
  height: 1.2rem;
  object-fit: cover;
}

.good-image-left {
  width: 23%;
  position: relative;
}

.good-image-left .icon-min {
  width: 0.2rem;
  position: absolute;
  bottom: 0.02rem;
  right: 0.04rem;
}

.good-image-left .icon-min img {
  width: 100%;
  height: 100%;
}

.good .good-title {
  width: 72%;
  margin-left: 5%;
  height: 1.2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.good .good-title .good_name {
  font-size: 0.17rem;
}

.good .good-title .good_price {
  font-size: 0.15rem;
  color: #9A2A25;
  margin: 0.02rem 0 0.15rem;
}

.good .good-title .good_description {
  font-size: 0.14rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #777;
}

.good_list {
  padding-left: 0.15rem;
  padding-right: 0.15rem;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  display: block;
}

.new_item {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.15rem;
  padding-right: 0.19rem;
  padding-bottom: 0.11rem;
  font-size: 0.15rem;
  color: #171717;
  display: flex;
}

.new_item::before {
  content: '';
  width: 0.12rem;
  height: 0.085rem;
  background: url('../assets/img/xwt.png') no-repeat;
  background-size: 100%;
  margin: auto 0;
  margin-right: 0.085rem;
}

.new_item>div:nth-child(1) {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.new_item>div:nth-child(2) {
  margin-left: 0.19rem;
}

.icon {
  position: absolute;
  top: 0.06rem;
  left: 0.09rem;
  width: 0.2rem;
  height: 0.2rem;
}

.icon img {
  width: 100%;
  height: 100%;
}

.collector {
  display: flex;
  align-items: center;
}

.collector img {
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 0.06rem;
  display: block;
  margin-right: 0.08rem;
}

.collector div {
  width: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #b38924;
}

.moreText {
  font-size: 0.15rem;
}

.cw_title {
  position: relative;
  font-size: 0.18rem;
  color: #9a2a25;
}

.cw_title::before {
  content: '';
  display: block;
  position: absolute;
  left: -0.3rem;
  top: 0.08rem;
  background: url(../assets/img/bjgw.png) no-repeat;
  background-size: cover;
  width: 0.17rem;
  height: 0.13rem;
}

.cw_title::after {
  content: '';
  display: block;
  position: absolute;
  right: -0.3rem;
  top: 0.08rem;
  background: url(../assets/img/bjgw.png) no-repeat;
  background-size: cover;
  width: 0.17rem;
  height: 0.13rem;
  transform: rotateY(180deg);
}

.text_bottom {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.15rem;
  color: #171717;
  font-size: 0.11rem;
  text-align: center;
  padding-bottom: 0.38rem;
}

.hisss {
  margin-right: 1px;
  background: url(../assets/img/hisss.png) no-repeat;
  width: 0.44rem;
  height: 0.2rem;
  background-size: 100% 100%;
}

.quxianx {
  margin-right: 0.1rem;
  background: url(../assets/img/quxianx.png) no-repeat;
  width: 0.44rem;
  height: 0.2rem;
  background-size: 100% 100%;
}

.creti-item{
  width: 100%;
  height: 2rem;
  overflow:hidden;
}
.creti-item .creti-image{
  max-width: 98%;
  height: 2rem;
}

.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    margin: 0 auto;
  }
  
.indicator_flex {
  display: flex;
}
.indicator_active {
  background: #b25660 !important;
}
.indicator_active1 {
  background: #fff !important;
}


.custom-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.1rem;
  right: 0.1rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.1rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0.1rem;
  color: #fff;
}
.custom_indicator {
  width: 100%;
  height: 0.235rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b6a21;
  
  position: absolute;
  bottom: 0.13rem;
}
.swipe-zhengshu{
  width: 92%;
  margin: 0 auto;
}
</style>
